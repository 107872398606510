import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Contact from "../components/contact"
import Seo from "../components/seo"

import jenAndBobby from "../images/jen-and-bobby.svg"
import ehb from "../images/teaser/ehb.png"
import tiny from "../images/teaser/tiny-homes.svg"
import monkeys from "../images/teaser/monkeys.svg"
import urec from "../images/teaser/urec.svg"
import danFlavin from "../images/teaser/dan-flavin.png"
// import fitforlife from "../images/teaser/fitforlife.png"
import quinova from "../images/teaser/quinova.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="light-grey clouds" id="top">
      <div className="content greeting space grid">
        <div className="col-2">
          <h1>Hi, I'm Jennifer!</h1>
          <p>
            I'm a UI/UX Designer from Thun, Switzerland. I work at{" "}
            <a href="https://www.swisscom.ch/" target="_blank" rel="noreferrer">
              Swisscom
            </a>{" "}
            and studied Interaction Design at the{" "}
            <a href="https://www.sfgb-b.ch/" target="_blank" rel="noreferrer">
              School for Design in Bern
            </a>
            .
          </p>
          <p>
            I strive to create digital experiences that are not just easy to
            understand and pleasant to look at, but inspire.
          </p>
        </div>
        <img
          className="col-1 floating-animation"
          src={jenAndBobby}
          alt="Jen and Bobby say welcome!"
        />
      </div>
    </div>
    <div className="content space" id="portfolio">
      <h3 className="small-space-below">Portfolio</h3>
      <div className="cards">
        <div>
          <Link to="/uri">
            <img className="padding" src={urec} alt="Canton of Uri" />
            <div>
              <h4>Electronic Coordination Platform</h4>
              <p>Webdesign, Logo design</p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/marketing-monkeys">
            <img className="contain" src={monkeys} alt="Marketing Monkeys" />
            <div>
              <h4>Marketing Monkeys</h4>
              <p>Character design</p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/tiny-house">
            <img className="padding" src={tiny} alt="Tiny Homes" />
            <div>
              <h4>Tiny House</h4>
              <p>Parallax Website</p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/ehb">
            <img className="cover" src={ehb} alt="EHB" />
            <div>
              <h4>EHB Digital Learning Platform</h4>
              <p>Concept, UX/UI Design</p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/dan-flavin">
            <img src={danFlavin} alt="Dan Flavin Poster" />
            <div>
              <h4>Dan Flavin</h4>
              <p>Poster Design</p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/quinova">
            <img className="cover" src={quinova} alt="Quinova" />
            <div>
              <h4>Quinova</h4>
              <p>Webdesign</p>
            </div>
          </Link>
        </div>
        {/*
        <div>
          <img src={fitforlife} alt="Fit for Life Mockup" />
          <Link to="/fit-for-life">
            <h3>Fit for Life</h3>
            <p>PWA Design</p>
          </Link>
        </div>
        */}
      </div>
    </div>
    <div className="light-grey">
      <Contact />
    </div>
  </Layout>
)

export default IndexPage
